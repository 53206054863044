@import 'reset';
@import 'style';

@font-face {
  font-family: 'Chillax';
  src: url('../../assets/fonts/Chillax-Variable.woff2') format('woff2'),
    url('../../assets/fonts/Chillax-Variable.woff') format('woff'),
    url('../../assets/fonts/Chillax-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

body {
  background-color: $background;
  font-family: 'Chillax', sans-serif;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  width: 100vw;

  button {
    font-family: 'Chillax', sans-serif;

    &:hover {
      cursor: pointer;
    }
  }

  color: white;

  div,
  ul,
  li,
  button,
  a {
    font-family: 'Chillax', sans-serif;
    display: flex;
  }

  #background {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
  }
  .main-container {
    gap: 4rem;
    padding: 8rem 4rem 4rem;

    .img-box-outer {
      background: $gradient-primary;
      padding: 0.25rem;
      border-radius: 1000rem;
      height: 100%;

      .img-box-inner {
        border-radius: 1000rem;
        padding: 0.25rem;
        background: $background;

        img {
          aspect-ratio: 1 / 1;
          width: auto;
          height: auto;
          max-width: 320px;
          max-height: 320px;
        }
      }
    }

    .content {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;

      .name {
        font-weight: 500;
        color: $label;
        font-size: 4rem;
      }

      .title {
        font-weight: 600;
        font-size: 4rem;
        background-image: $gradient-primary;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .menu {
        gap: 1rem;
        flex-direction: column;
        text-align: center;

        .menu-item {
          flex-direction: column;
          gap: 0.5rem;
          overflow: hidden;
          max-height: 2rem;
          height: 2rem;
          flex-basis: content;
          &.show {
            animation-delay: 0s;
            animation: show-anim 1s ease-out forwards;
            .accordion-toggle {
              color: $label;
              &:after {
                animation-delay: 0s;
                animation: extend-anim 0.5s ease-out forwards;
              }
            }
          }
          &.hide {
            animation-delay: 0s;
            animation: hide-anim 1s ease-out forwards;
            .accordion-toggle:after {
              animation-delay: 0s;
              animation: collapse-anim 1s ease-out forwards;
            }
          }
          @keyframes extend-anim {
            from {
              transform: rotate(-90deg);
            }
            to {
              transform: rotate(0deg);
            }
          }
          @keyframes collapse-anim {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(-90deg);
            }
          }
          @keyframes show-anim {
            from {
              max-height: 2rem;
              height: 2rem;
            }
            to {
              max-height: 100%;
              height: max-content;
            }
          }
          @keyframes hide-anim {
            from {
              max-height: 100%;
              height: max-content;
            }
            to {
              max-height: 2rem;
              height: 2rem;
            }
          }
        }

        .working-on-container {
          .check-working-title:before {
            display: inline-block;
            content: '';
            width: 2rem;
            height: 2rem;
            -webkit-mask: url('../../assets/icons/work.svg') no-repeat 50% 50%;
            mask: url('../../assets/icons/work.svg') no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-image: $gradient-primary;
          }

          .accordion-container.github {
            a {
              text-decoration: none;
              align-items: center;
              gap: 0.5rem;
              color: $placeholder;

              &:before {
                display: inline-block;
                content: '';
                width: 1.5rem;
                height: 1.5rem;
                -webkit-mask: url('../../assets/icons/github.svg') no-repeat 50%
                  50%;
                mask: url('../../assets/icons/github.svg') no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                background-color: $placeholder;
              }

              &:hover {
                color: $caption;

                &:before {
                  background-color: $caption;
                }
              }
            }
          }
        }

        .contact-me-container {
          .contact-me-title:before {
            display: inline-block;
            content: '';
            width: 2rem;
            height: 2rem;
            -webkit-mask: url('../../assets/icons/contact.svg') no-repeat 50%
              50%;
            mask: url('../../assets/icons/contact.svg') no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-image: $gradient-primary;
          }
        }

        .social-containerz {
          .social-titlez:before {
            display: inline-block;
            content: '';
            width: 2rem;
            height: 2rem;
            -webkit-mask: url('../../assets/icons/socials.svg') no-repeat 50%
              50%;
            mask: url('../../assets/icons/socials.svg') no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-image: $gradient-primary;
          }
        }

        .accordion-toggle {
          border: none;
          align-items: center;
          background-color: transparent;
          color: $caption;
          font-size: 1.25rem;
          font-weight: 500;
          gap: 0.5rem;

          &:hover {
            color: $label;
            &:after {
              background-color: $caption;
            }
          }

          &:after {
            display: inline-block;
            content: '';
            width: 1rem;
            height: 1rem;
            -webkit-mask: url('../../assets/icons/arrow.svg') no-repeat 50% 50%;
            mask: url('../../assets/icons/arrow.svg') no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            transform: rotate(-90deg);
            background-color: $placeholder;
          }
        }

        .accordion-container {
          display: flex;
          flex-direction: column;
          color: $placeholder;
          padding-left: 2.5rem;
          gap: 0.5rem;
          li {
            &.accordion-item {
              a {
                text-decoration: none;
                align-items: center;
                gap: 0.5rem;
                color: $placeholder;

                &:before {
                  display: inline-block;
                  content: '';
                  width: 1.5rem;
                  height: 1.5rem;
                  -webkit-mask-size: cover;
                  mask-size: cover;
                  background-color: $placeholder;
                }

                &:hover {
                  color: $caption;

                  &:before {
                    background-color: $caption;
                  }
                }
              }

              &.calendly > a:before {
                -webkit-mask: url('../../assets/icons/calendly.svg') no-repeat
                  50% 50%;
                mask: url('../../assets/icons/calendly.svg') no-repeat 50% 50%;
              }

              &.telegram > a:before {
                -webkit-mask: url('../../assets/icons/telegram.svg') no-repeat
                  50% 50%;
                mask: url('../../assets/icons/telegram.svg') no-repeat 50% 50%;
              }

              &.discord > a:before {
                -webkit-mask: url('../../assets/icons/discord.svg') no-repeat
                  50% 50%;
                mask: url('../../assets/icons/discord.svg') no-repeat 50% 50%;
              }

              &.email > a:before {
                -webkit-mask: url('../../assets/icons/email.svg') no-repeat 50%
                  50%;
                mask: url('../../assets/icons/email.svg') no-repeat 50% 50%;
              }

              &.twitter > a:before {
                -webkit-mask: url('../../assets/icons/twitter.svg') no-repeat
                  50% 50%;
                mask: url('../../assets/icons/twitter.svg') no-repeat 50% 50%;
              }

              &.linkedin > a:before {
                -webkit-mask: url('../../assets/icons/linkedin.svg') no-repeat
                  50% 50%;
                mask: url('../../assets/icons/linkedin.svg') no-repeat 50% 50%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  body {
    .main-container {
      flex-direction: column;
      gap: 2rem;
      padding: 2rem;
      justify-content: center;
      text-align: center;
      align-items: center;

      .img-box-outer {
        .img-box-inner {
          img {
            height: 240px;
          }
        }
      }
      .content {
        .menu {
          .menu-item {
            flex-basis: auto;
          }
          .accordion-toggle {
            text-align: left;
            font-size: 1rem;
          }
        }

        .name {
          font-size: 2.25rem;
          align-self: center;
        }

        .title {
          font-size: 2.25rem;
          align-self: center;
        }
      }
    }
  }
}
