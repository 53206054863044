html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
}

:focus, :active {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

@font-face {
  font-family: Chillax;
  src: url("Chillax-Variable.2b6c3837.woff2") format("woff2"), url("Chillax-Variable.3469cdc9.woff") format("woff"), url("Chillax-Variable.2d22751a.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

body {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  color: #fff;
  background-color: #212121;
  justify-content: center;
  align-items: start;
  font-family: Chillax, sans-serif;
  display: flex;
}

body button {
  font-family: Chillax, sans-serif;
}

body button:hover {
  cursor: pointer;
}

body div, body ul, body li, body button, body a {
  font-family: Chillax, sans-serif;
  display: flex;
}

body #background {
  z-index: -1;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  position: absolute;
}

body .main-container {
  gap: 4rem;
  padding: 8rem 4rem 4rem;
}

body .main-container .img-box-outer {
  height: 100%;
  background: linear-gradient(135deg, #45e645 0%, #39b7bf 50%, #4555e6 100%);
  border-radius: 1000rem;
  padding: .25rem;
}

body .main-container .img-box-outer .img-box-inner {
  background: #212121;
  border-radius: 1000rem;
  padding: .25rem;
}

body .main-container .img-box-outer .img-box-inner img {
  aspect-ratio: 1 / 1;
  width: auto;
  height: auto;
  max-width: 320px;
  max-height: 320px;
}

body .main-container .content {
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

body .main-container .content .name {
  color: #f5f5f5;
  font-size: 4rem;
  font-weight: 500;
}

body .main-container .content .title {
  background-image: linear-gradient(135deg, #45e645 0%, #39b7bf 50%, #4555e6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4rem;
  font-weight: 600;
}

body .main-container .content .menu {
  text-align: center;
  flex-direction: column;
  gap: 1rem;
}

body .main-container .content .menu .menu-item {
  max-height: 2rem;
  height: 2rem;
  flex-direction: column;
  flex-basis: content;
  gap: .5rem;
  overflow: hidden;
}

body .main-container .content .menu .menu-item.show {
  animation: show-anim 1s ease-out forwards;
}

body .main-container .content .menu .menu-item.show .accordion-toggle {
  color: #f5f5f5;
}

body .main-container .content .menu .menu-item.show .accordion-toggle:after {
  animation: extend-anim .5s ease-out forwards;
}

body .main-container .content .menu .menu-item.hide {
  animation: hide-anim 1s ease-out forwards;
}

body .main-container .content .menu .menu-item.hide .accordion-toggle:after {
  animation: collapse-anim 1s ease-out forwards;
}

@keyframes extend-anim {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes collapse-anim {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-90deg);
  }
}

@keyframes show-anim {
  from {
    max-height: 2rem;
    height: 2rem;
  }

  to {
    max-height: 100%;
    height: max-content;
  }
}

@keyframes hide-anim {
  from {
    max-height: 100%;
    height: max-content;
  }

  to {
    max-height: 2rem;
    height: 2rem;
  }
}

body .main-container .content .menu .working-on-container .check-working-title:before {
  content: "";
  width: 2rem;
  height: 2rem;
  -webkit-mask: url("work.082f638d.svg") 50% no-repeat;
  -webkit-mask: url("work.082f638d.svg") 50% no-repeat;
  mask: url("work.082f638d.svg") 50% no-repeat;
  background-image: linear-gradient(135deg, #45e645 0%, #39b7bf 50%, #4555e6 100%);
  display: inline-block;
  -webkit-mask-size: cover;
  mask-size: cover;
}

body .main-container .content .menu .working-on-container .accordion-container.github a {
  color: #666;
  align-items: center;
  gap: .5rem;
  text-decoration: none;
}

body .main-container .content .menu .working-on-container .accordion-container.github a:before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("github.e9af4884.svg") 50% no-repeat;
  -webkit-mask: url("github.e9af4884.svg") 50% no-repeat;
  mask: url("github.e9af4884.svg") 50% no-repeat;
  background-color: #666;
  display: inline-block;
  -webkit-mask-size: cover;
  mask-size: cover;
}

body .main-container .content .menu .working-on-container .accordion-container.github a:hover {
  color: #b3b3b3;
}

body .main-container .content .menu .working-on-container .accordion-container.github a:hover:before {
  background-color: #b3b3b3;
}

body .main-container .content .menu .contact-me-container .contact-me-title:before {
  content: "";
  width: 2rem;
  height: 2rem;
  -webkit-mask: url("contact.f3e7098c.svg") 50% no-repeat;
  -webkit-mask: url("contact.f3e7098c.svg") 50% no-repeat;
  mask: url("contact.f3e7098c.svg") 50% no-repeat;
  background-image: linear-gradient(135deg, #45e645 0%, #39b7bf 50%, #4555e6 100%);
  display: inline-block;
  -webkit-mask-size: cover;
  mask-size: cover;
}

body .main-container .content .menu .social-containerz .social-titlez:before {
  content: "";
  width: 2rem;
  height: 2rem;
  -webkit-mask: url("socials.ec86a419.svg") 50% no-repeat;
  -webkit-mask: url("socials.ec86a419.svg") 50% no-repeat;
  mask: url("socials.ec86a419.svg") 50% no-repeat;
  background-image: linear-gradient(135deg, #45e645 0%, #39b7bf 50%, #4555e6 100%);
  display: inline-block;
  -webkit-mask-size: cover;
  mask-size: cover;
}

body .main-container .content .menu .accordion-toggle {
  color: #b3b3b3;
  background-color: #0000;
  border: none;
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 500;
}

body .main-container .content .menu .accordion-toggle:hover {
  color: #f5f5f5;
}

body .main-container .content .menu .accordion-toggle:hover:after {
  background-color: #b3b3b3;
}

body .main-container .content .menu .accordion-toggle:after {
  content: "";
  width: 1rem;
  height: 1rem;
  -webkit-mask: url("arrow.142217e7.svg") 50% no-repeat;
  -webkit-mask: url("arrow.142217e7.svg") 50% no-repeat;
  mask: url("arrow.142217e7.svg") 50% no-repeat;
  background-color: #666;
  display: inline-block;
  transform: rotate(-90deg);
  -webkit-mask-size: cover;
  mask-size: cover;
}

body .main-container .content .menu .accordion-container {
  color: #666;
  flex-direction: column;
  gap: .5rem;
  padding-left: 2.5rem;
  display: flex;
}

body .main-container .content .menu .accordion-container li.accordion-item a {
  color: #666;
  align-items: center;
  gap: .5rem;
  text-decoration: none;
}

body .main-container .content .menu .accordion-container li.accordion-item a:before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  background-color: #666;
  display: inline-block;
  -webkit-mask-size: cover;
  mask-size: cover;
}

body .main-container .content .menu .accordion-container li.accordion-item a:hover {
  color: #b3b3b3;
}

body .main-container .content .menu .accordion-container li.accordion-item a:hover:before {
  background-color: #b3b3b3;
}

body .main-container .content .menu .accordion-container li.accordion-item.calendly > a:before {
  -webkit-mask: url("calendly.0f723e52.svg") 50% no-repeat;
  -webkit-mask: url("calendly.0f723e52.svg") 50% no-repeat;
  mask: url("calendly.0f723e52.svg") 50% no-repeat;
}

body .main-container .content .menu .accordion-container li.accordion-item.telegram > a:before {
  -webkit-mask: url("telegram.bd8de2dd.svg") 50% no-repeat;
  -webkit-mask: url("telegram.bd8de2dd.svg") 50% no-repeat;
  mask: url("telegram.bd8de2dd.svg") 50% no-repeat;
}

body .main-container .content .menu .accordion-container li.accordion-item.discord > a:before {
  -webkit-mask: url("discord.feb39c62.svg") 50% no-repeat;
  -webkit-mask: url("discord.feb39c62.svg") 50% no-repeat;
  mask: url("discord.feb39c62.svg") 50% no-repeat;
}

body .main-container .content .menu .accordion-container li.accordion-item.email > a:before {
  -webkit-mask: url("email.c0fd9c3d.svg") 50% no-repeat;
  -webkit-mask: url("email.c0fd9c3d.svg") 50% no-repeat;
  mask: url("email.c0fd9c3d.svg") 50% no-repeat;
}

body .main-container .content .menu .accordion-container li.accordion-item.twitter > a:before {
  -webkit-mask: url("twitter.b847f499.svg") 50% no-repeat;
  -webkit-mask: url("twitter.b847f499.svg") 50% no-repeat;
  mask: url("twitter.b847f499.svg") 50% no-repeat;
}

body .main-container .content .menu .accordion-container li.accordion-item.linkedin > a:before {
  -webkit-mask: url("linkedin.e88e6306.svg") 50% no-repeat;
  -webkit-mask: url("linkedin.e88e6306.svg") 50% no-repeat;
  mask: url("linkedin.e88e6306.svg") 50% no-repeat;
}

@media only screen and (max-width: 960px) {
  body .main-container {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
  }

  body .main-container .img-box-outer .img-box-inner img {
    height: 240px;
  }

  body .main-container .content .menu .menu-item {
    flex-basis: auto;
  }

  body .main-container .content .menu .accordion-toggle {
    text-align: left;
    font-size: 1rem;
  }

  body .main-container .content .name, body .main-container .content .title {
    align-self: center;
    font-size: 2.25rem;
  }
}

/*# sourceMappingURL=index.7b2ecd73.css.map */
