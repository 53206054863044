//Primary
$background: #212121;
$gradient-primary: linear-gradient(
  135deg,
  #45e645 0%,
  #39b7bf 50%,
  #4555e6 100%
);
$label: #f5f5f5;
$caption: #b3b3b3;
$placeholder: #666666;

//breakpoint-tablet
$breakpoint-tablet: 960px;
